/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --mdc-dialog-container-color: #fff;
  --mdc-dialog-subhead-size: 1.25rem;
}
/* html, body { height: 100%; } */
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.table thead td,
.table thead th {
  z-index: 9 !important;
}


.branding-bg{
  background-image: url('/media/images/2600x1200/bg-5.png');
  background-size: cover;
}


.ng-value-label, .ng-option-label, .ng-placeholder {
  font-size: 0.8125rem !important;
}
